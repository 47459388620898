(function () {
  'use strict';

  angular
    .module('duo-routes.atribuicao-rotas', [
      'ui.router',
      'ui.bootstrap',
      'blockUI',
      'restangular',
      'httpServiceV3gModule',
      'ConstantVfiltroModule',
      'ServiceVfiltroModule',
      'FactoryVfiltroModule',
      'ObjetosVfiltroModule',
      'safetyModule',
      'ngSanitize'
    ]);
}());
