(function () {
  'use strict';

  angular
    .module('duo-routes.cadastro-rotas')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.duo-routes-cadastro', {
      url: '/duo-routes/cadastro?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'duo-routes/cadastro/cadastro-duo-routes.tpl.html',
          controller: 'CadastroDuoRoutesCtrl',
          controllerAs: 'cadastroDuoRoutesCtrl'
        }
      },
      data: {
        hideTitulo: true,
        roles: [['ROTA_VISUALIZAR']]
      }
    });
  }
}());
