(function () {
  'use strict';

  /* global $, moment */
  $.AdminLTE.options.enableBSToppltip = false;
  $.AdminLTE.options.navbarMenuSlimscroll = false;
  // Set moment to serialize dates to json
  /*eslint-disable */
  Date.prototype.toJSON = function () {
    return moment(this).format();
  };
  /*eslint-enable */

  angular
    .module('conducaoEconomica')
    .config(config);

  function config(stConfig, blockUIConfig, growlProvider, $httpProvider, $locationProvider) {
    stConfig.pagination.template = 'template/pagination-template.html';
    blockUIConfig.message = '...';

    function ignorarModalCarregando(blockConfig) {
      if (blockConfig.url.match(/ponto-referencia/) ||
        blockConfig.url.match(/status-veiculo/) ||
        blockConfig.url.match(/alerta\/quantidade/) ||
        blockConfig.url.match(/alerta\/one-page/) ||
        blockConfig.url.match(/alerta\/ranking-infracoes/) ||
        blockConfig.url.match(/configuracao\/configuracoes\/[0-9]*\/alerta/) ||
        blockConfig.url.match(/alerta\/notificacoes\/[0-9]*/) ||
        blockConfig.url.match(/.*duo-routes.*/)
      ) {
        return false;
      }
      return '...';
    }
    blockUIConfig.requestFilter = ignorarModalCarregando;

    growlProvider.globalTimeToLive(3000);
    growlProvider.globalPosition('bottom-right');

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $httpProvider.defaults.headers.common.Accept = 'application/json';

    $locationProvider.html5Mode(true);
  }
}());
