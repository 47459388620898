/* global document, Intl */
(function () {
  'use strict';

  class AtribuicaoDuoRoutesCtrl {
    constructor(Keys, ServiceVfiltro, FactoryVfiltro, safetyService, Authenticator, $window, $document, $scope, $log, blockUI) {
      this.keys = Keys;
      this.factoryVfiltro = FactoryVfiltro;
      this.serviceVfiltro = ServiceVfiltro;
      this.authenticator = Authenticator;
      this.safetyService = safetyService;
      this.window = $window;
      this.document = $document[0];
      this.log = $log;
      this.scope = $scope;
      this.blockUI = blockUI;

      this.filtro = null;
      this.user = null;

      this.initialize();
    }

    initialize() {
      this.blockUI.start();
      return this.initFilter()
        .then(() => this.loadUser())
        .finally(() => {
          this.blockUI.reset();
          this.blockUI.stop();
        });
    }

    initFilter() {
      return this.serviceVfiltro.init(
        this.factoryVfiltro.get([
          {
            name: 'uoId',
            key: this.keys.uo.name
          }
        ])
      ).then((filtro) => {
        this.filtro = filtro;
      });
    }

    loadUser() {
      return this.authenticator.getUser()
        .then((user) => {
          this.user = user;
        });
    }

  }

  angular
    .module('duo-routes.atribuicao-rotas')
    .controller('AtribuicaoDuoRoutesCtrl', AtribuicaoDuoRoutesCtrl);
}());
