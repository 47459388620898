(function () {
  'use strict';

  angular
    .module('duo-routes.atribuicao-rotas')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.duo-routes-atribuicao', {
      url: '/duo-routes/atribuicao?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'duo-routes/atribuicao/atribuicao-duo-routes.tpl.html',
          controller: 'AtribuicaoDuoRoutesCtrl',
          controllerAs: 'atribuicaoDuoRoutesCtrl'
        }
      },
      data: {
        titulo: 'ce.duoRoutes.atribuicao.titulo',
        tituloDescricao: '',
        roles: [['ATRIBUICAO_ROTA_VISUALIZAR']]
      }
    });
  }
}());
