(function () {
  'use strict';

  angular
    .module('duo-routes', [
      'ui.router',
      'duo-routes.cadastro-rotas',
      'duo-routes.atribuicao-rotas'
    ]);
}());
