/* global document */
/* eslint-disable angular/json-functions */
(function () {
  'use strict';

  class StateUrlService {
    constructor($location, $window, $rootScope, $timeout, Authenticator, $state, $document, ModalInfoConfirmService,
      InformativoKeys, FeatureFlag, MotoristaService, SolucoesEnum, GoogleTagManagerUtils, FeatureOuService, CadastroVeiculoService,
      FeaturehubJsSdk, FeatureHubConfigKeys
    ) {
      this.location = $location;
      this.window = $window;
      this.rootScope = $rootScope;
      this.authenticator = Authenticator;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.state = $state;
      this.timeout = $timeout;
      this.document = $document;
      this.FeatureOuService = FeatureOuService;
      this.modalInfoService = ModalInfoConfirmService;
      this.veiculoService = CadastroVeiculoService;
      this.FeaturehubJsSdk = FeaturehubJsSdk;
      this.FeatureHubConfigKeys = FeatureHubConfigKeys;

      this.PAGINAS_REQUIRED_INTELLIGENCE = [
        'root.indicadorPowerBi',
        'root.quadroOcorrencias',
        'root.onePage',
        'root.rankingInfracoes',
        'root.rankingPontos',
        'root.mapaCalor',
        'root.configEventos',
        'root.configVdrivers'
      ];
      this.SUBPAGES_NO_REQUIRED_INTELLIGENCE = [
        '',
        'turnos',
        'tipos'
      ];
      this.PRODUCT_VALIDATION_SNACKBAR_PAGES = [
        'root.historicoTimeline',
        'root.mapasGrid',
        'root.historicoAlerta'
      ];
      this.SCOPE_WITH_SUBPAGES = 'root.configEventos';
      this.informativoKeys = InformativoKeys;
      this.featureFlag = FeatureFlag;
      this.motoristaService = MotoristaService;
      this.solucoesEnum = SolucoesEnum;
      this.lastPage = '';
      this.flagBundle = null;
    }

    initInterceptInformativo() {
      if (this.featureFlag.SOLUCOES) {
        this.rootScope.$on('$stateChangeStart', this.interceptParaInformativo.bind(this));
      }
    }

    interceptParaInformativo(event, toState) {
      if (this.lastPage === toState.name || this.isInformativoPage(toState.name)) {
        return;
      }
      this.lastPage = toState.name;

      this.authenticator.getUser().then((user) => {
        if (!this.hasRolePermission(toState, user)) {
          this.checkAlternativeAccess(toState.data)
            .then(hasPermission => {
              if (!hasPermission) {
                event.preventDefault();
                this.sendToInformativo(toState);
              }
            });
        }
      });
    }

    isInformativoPage(page) {
      return ['root.informativo', 'root.planos'].includes(page);
    }

    hasRolePermission(toState, user) {
      return toState.data &&
        (toState.data.roles || toState.data.role) &&
        this.checkPermissaoSolucaoUsuario(user, toState.data.roles ? toState.data.roles : toState.data.role);
    }

    checkPermissaoSolucaoUsuario(user, stateAllowedRoles) {
      const usuarioRolesSolucao = user.permissoesSolucao;
      if (angular.isString(stateAllowedRoles)) {
        return usuarioRolesSolucao.indexOf(stateAllowedRoles) !== -1;
      }
      if (angular.isArray(stateAllowedRoles)) {
        return stateAllowedRoles.some(allowedRole => {
          return this.checkPermissaoRole(allowedRole, usuarioRolesSolucao);
        });
      }

      return false;
    }

    checkAlternativeAccess(stateData) {
      const bundles = stateData && stateData.accessByBundle ? stateData.accessByBundle : [],
          premiumTelemetry = stateData && stateData.accessByPremiumTelemetry ? stateData.accessByPremiumTelemetry : false;

      if (bundles.length > 0 || premiumTelemetry) {
        return this.loadFeatureFlagsWithUo().then(featureFlag => {
          if (featureFlag) {
            return this.verifyBundles(this.rootScope.usuario.uo.id, bundles, premiumTelemetry);
          }
          return Promise.resolve(false);
        });
      }

      return Promise.resolve(false);
    }

    verifyBundles(uoId, bundles, premiumTelemetry) {
      return this.veiculoService.hasBundle(uoId, bundles, premiumTelemetry)
        .then((response) => response)
        .catch(() => false);
    }

    sendToInformativo(toState) {
      const informativoState = this.informativoKeys[toState.name];
      if (informativoState) {
        this.state.go('root.informativo', {
          informativoKey: informativoState[0].key,
          uoId: this.rootScope.usuario.uo.id
        });
      } else {
        this.state.go('root.planos');
      }
    }

    checkPermissaoRole(role, permissoes) {
      if (angular.isString(role)) {
        return permissoes.indexOf(role) !== -1;
      }
      // must have all roles of this when it's an array
      return role.filter(roleVal => this.checkPermissaoRole(roleVal, permissoes)).length === role.length;
    }

    onPageChange() {
      this.authenticator.getUser().then(user => {
        this.rootScope.$on('$viewContentLoaded', () => {
          const keyLastPage = `lastPage-${user.id}`,
            urlState = this.location.url();
          if (urlState !== '/informativo') {
            this.window.localStorage.setItem(keyLastPage, urlState);
          }
        });
      });
    }

    getSanitizedPathName() {
      const { pathname } = this.document[0].location;
      return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    }

    isValidPathname(pathname) {
      return pathname && !pathname.includes('/state=');
    }

    getLastVisitedPageReloadReloadNaConfig(userUoId) {
      return this.FeatureOuService.getLastVisitedPageReload(userUoId).then((lastVisitedPageReload) => {
        this.isTTCUser = lastVisitedPageReload;
        return lastVisitedPageReload;
      }).catch((error) => {
        this.log.error('Error loading featureOu config: ', error);
        return false;
      });
    }

    setLastUrl() {
      this.authenticator.getUser().then(user => {
        const { id, uo } = user,
          lastPage = this.window.localStorage.getItem(`lastPage-${id}`);
        this.getLastVisitedPageReloadReloadNaConfig(uo.id).then(() => {
          const pageDefault = this.setDefaultPage(user),
            url = this.constructUrl(pageDefault, lastPage);
          this.location.url(url);
        });
      });
    }

    constructUrl(pageDefault, lastPage) {
      if (this.isTTCUser) {
        const pathname = this.getSanitizedPathName();
        return pageDefault || (this.isValidPathname(pathname) ? pathname : lastPage);
      }
      return lastPage || pageDefault;
    }

    setDefaultPage(user) {
      if (this.isTTCUser) {
        return '/mapas-grid';
      }
      if (
        this.featureFlag.MENU_INSIGHTS &&
        user.permissoesSolucao.includes('PREDICAO_ACIDENTE_VISUALIZAR') &&
        user.permissoes.includes('PREDICAO_ACIDENTE_VISUALIZAR') &&
        user.permissoes.includes('DADOS_SENSIVEIS_VISUALIZAR') &&
        user.permissoes.includes('ADMIN_VELTEC')
      ) {
        return '/seguranca';
      }
      let defaultPage = '/direcao-segura';
      if (!user.permissoes.includes('DIRECAO_SEGURA_VISUALIZAR')) {
        defaultPage = user.permissoes.includes('LOCALIZACAO_ATUAL_VISUALIZAR') ? '/mapas-grid' : '/relatorios/bdv';
      }
      return defaultPage;
    }

    onPageLoad() {
      const currentState = this.state.current.name,
        subPagina = this.state.params.tipoSubpagina,
        scopeWithSubPages = currentState === this.SCOPE_WITH_SUBPAGES,
        subPaginaSemRestricao = scopeWithSubPages && this.SUBPAGES_NO_REQUIRED_INTELLIGENCE.includes(subPagina);

      this.authenticator.getUser().then(user => {
        const urlState = this.location.url();
        if (!this.state.previous || this.state.current.name !== this.state.previous.name) {
          this.googleTagManagerUtils.sendEventClickGTM('page_view', user, { page: urlState });
        }
        if (this.featureFlag.SOLUCOES_AVISO && this.PAGINAS_REQUIRED_INTELLIGENCE.includes(currentState) && !subPaginaSemRestricao) {
          if (!user.solucoes || !this.solucoesEnum.hasIntelligence(user.solucoes)) {
            this.openModalBloqueio();
          }
        }
      });
    }

    onProductValidationSnackbarLoad() {
      if (this.featureFlag.SNACKBAR_PRODUCT_VALIDATION) {
        const currentState = this.state.current.name;

        if (this.PRODUCT_VALIDATION_SNACKBAR_PAGES.includes(currentState) && this.authenticator.hasRole('MOTORISTA_EDITAR')) {
          this.authenticator.getUser().then(user => {
            /* eslint-disable */
            const usuario = user.plain(),
              isSnackBarInfoInLocalStorage = this.window.localStorage.getItem(`snackbar-${usuario.id}`, 'true');

            if (!isSnackBarInfoInLocalStorage) {
              this.appendSnackbarToVFleetsBody(usuario);
            }
          });
        } else {
          const snackbarRef = document.querySelector('.product-validation-snackbar');
          /* eslint-enable */
          if (snackbarRef) {
            snackbarRef.remove();
          }
        }
      }
    }

    appendSnackbarToVFleetsBody(usuario) {
      /* eslint-disable */
      const snackbarAtual = document.querySelector('body').querySelector('vfwc-product-validation-snackbar');
      if (snackbarAtual) {
        snackbarAtual.remove();
      } else {
        this.motoristaService.getMotoristasSemFoto(usuario.uo.id).then(motoristas => {
          const motoristasSemFotoResponse = motoristas.filter(motorista => this.hasFotoCadastro(motorista.fotos));

          const elementContainer = document.querySelector('body'),
            snackBarElement = document.createElement('vfwc-product-validation-snackbar'),
            userUOHasMotoristaSemFoto = motoristasSemFotoResponse.length > 0;
          /* eslint-enable */

          if (userUOHasMotoristaSemFoto && snackBarElement) {
            snackBarElement.addEventListener('onIrParaCadastroMotorista', () => {
              this.state.go('root.motorista');
            });

            snackBarElement.usuarioId = usuario.id;
            elementContainer.appendChild(snackBarElement);
          }
        });
      }
    }

    hasFotoCadastro(fotos) {
      return Boolean(fotos && fotos.find(foto => foto.fotoCadastro && !foto.excluido && !foto.pretoBranco));
    }

    openModalBloqueio() {
      this.timeout(() => {
        this.modalInfoService.confirmSingleModal({
          title: 'Atualize seu plano comercial para manter esta funcionalidade!',
          btnPrimario: 'Ver Planos',
          conteudo: `Em breve esta funcionalidade integrará o módulo <strong>Intelligence</strong>. Para continuar com acesso
          a esse e outros recursos, entre em contato com o seu <strong>consultor comercial</strong> ou nos contate pelo Portal Veltec.`,
          headerColor: 'bg-green modal-bloqueio-paginas',
          typeButton: 'btn-success'
        }, () => this.window.open('/planos-solucoes', '_blank'));
      }, 1000);
    }

    loadFeatureFlagsWithUo() {
      if (this.flagBundle !== null) {
        return Promise.resolve(this.flagBundle);
      }
      return this.authenticator.getUser().then((user) => {
        return this.FeaturehubJsSdk.setUserKey(user && user.id).then(() => {
          return this.FeaturehubJsSdk.setAttributeValue('uo', user && user.uo && user.uo.id);
        })
        .then(() => {
          this.flagBundle = this.FeaturehubJsSdk.isFeatureEnabled(this.FeatureHubConfigKeys.ENABLE_SCREEN_ACCESS_CHECK_BUNDLE);
          return this.flagBundle;
        });
      });
    }
  }

  angular
    .module('stateUrlModule', [
      'ui.router',
      'v3gAuthenticatorModule',
      'modalInfoConfirmModule',
      'informativoKeysModule',
      'FeatureFlagModule',
      'motoristaModule',
      'SolucoesModule'
    ])
    .service('StateUrlService', StateUrlService);
}());
