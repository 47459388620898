(function () {
  'use strict';

  angular
    .module('relatorios.deviceHealth')
    .config(config);

  function config($stateProvider) {
    $stateProvider.state('root.device-health', {
      url: '/tempo-real/device-health?vfiltro=',
      views: {
        'content@': {
          templateUrl: 'relatorios/device-health/device-health.tpl.html',
          controller: 'DeviceHealthCtrl'
        }
      },
      data: {
        titulo: 'devices-health.title',
        roles: [[]]
      }
    });
  }
}());
