(function () {
  'use strict';

  /* @ngdoc object
   * @name relatorios.deviceHealth
   * @description
   *
   */
  angular
    .module('relatorios.deviceHealth', [
      'ui.router',
      'restangular',
      'angularMoment',
      'FeatureFlagModule',
      'FeatureOuModule',
      'ServiceVfiltroModule',
      'ObjetosVfiltroModule',
      'FactoryVfiltroModule',
      'ConstantVfiltroModule'
    ]);
}());
